import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import "../../src/fonts/fonts.css";
import Page from '../../src/components/Page';
import FullLogo from "images/appnigma_full_logo.webp";
import appnigmaIcon from "images/appnigma_icon.webp";
import "../../src/styles/animation.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {


  return (
    <React.Fragment>
      <Helmet>
        <html lang='en' />
        <meta charset="utf-8" />
        <link rel="shortcut icon" href={appnigmaIcon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Generate code to bridge Salesforce & other apps, powered by AI"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={FullLogo} />
        <meta property="og:title" content="Appnigma AI" />
        <meta
          property="og:description"
          content="Generate code to bridge Salesforce & other apps, powered by AI"
        />
        <meta property="og:url" content="https://appnigma.ai/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        {/* Optimizing Images by preloading them */}
        <link rel="preload" href={FullLogo} as="image" />
        <link rel="preload" href={appnigmaIcon} as="image" />
        <title>Appnigma AI</title>
      </Helmet>
      <Page>{props.children}</Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
