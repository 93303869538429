import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { light } from "./palette";

let theme = createTheme({
  typography: {
    // Global font settings
    fontFamily:
      "'Geometria-Bold', 'Geometria-BoldItalic', 'Geometria-ExtraBold', 'Geometria-ExtraBoldItalic', 'Geometria-ExtraLight', 'Geometria-ExtraLightItalic', 'Geometria-Heavy', 'Geometria-HeavyItalic', 'Geometria-Italic', 'Geometria-Light', 'Geometria-LightItalic', 'Geometria-Medium', 'Geometria-MediumItalic', 'Geometria-Thin', 'Geometria-ThinItalic', 'Geometria', sans-serif",
    fontSize: 14, // base font size

    h1: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "56px",
      color: light.text.header,
      whiteSpace: "normal",
    },
    h1Italic: {
      fontFamily: "'Geometria-ExtraBoldItalic', sans-serif",
      fontSize: "56px",
    },
    h2: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "48px",
      color: light.text.header2,
    },
    h3: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "36px",
      color: light.text.header2,
    },
    h4: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "30px",
      color: light.text.header2,
    },
    h5: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "24px",
      color: light.text.description2,
    },
    h6: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "20px",
      color: light.text.header2,
    },
    h6extraBold: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "18px",
      color: light.text.description2,
    },
    faq: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "24px",
      color: light.text.header2,
    },
    pricingTitle: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "32px",
      color: light.text.pricing,
    },
    pricingPrice: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "60px",
      color: light.text.pricing,
    },
    caption: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "12px",
      color: light.text.pricing,
    },
    caption2: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "16px",
      color: light.text.caption,
    },
    navLink: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "16px",
      color: light.text.header2,
    },
    radioBtnText: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "20px",
      color: light.text.radioBtn,
    },
    blogText: {
      fontFamily: "'Geometria', sans-serif",
      fontSize: "20px",
      color: light.text.description2,
    },
    body1: {
      fontFamily: "'Geometria', sans-serif",
      fontSize: "16px",
      color: light.text.body1,
    },
    body2: {
      fontFamily: "'Geometria', sans-serif",
      fontSize: "12px",
    },
    footer: {
      fontFamily: "'Geometria', sans-serif",
      fontSize: "15px",
      color: light.text.light,
    },
    description: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "20px",
      color: light.text.description,
      whiteSpace: "normal",
    },
    description2: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "16px",
      color: light.text.description2,
      whiteSpace: "normal",
    },
    titleExtraBold: {
      fontFamily: "'Geometria-ExtraBold', sans-serif",
      fontSize: "40px",
      color: light.text.pricing,
    },
    title: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "30px",
      color: light.text.header2,
    },
    title1: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "24px",
      color: light.text.header2,
    },
    title2: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "20px",
      color: light.text.header2,
    },
    title3: {
      fontFamily: "'Geometria-Bold', sans-serif",
      fontSize: "16px",
      color: light.text.header2,
    },
    subtitle1: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "24px",
      color: light.text.description2,
      whiteSpace: "normal",
    },
    subtitle2: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "20px",
      color: light.text.description2,
      whiteSpace: "normal",
    },
    subtitle3: {
      fontFamily: "'Geometria-Medium', sans-serif",
      fontSize: "16px",
      color: light.text.description2,
      whiteSpace: "normal",
    },
    subtitleLight: {
      fontFamily: "'Geometria', sans-serif",
      fontSize: "10px",
      color: light.text.description,
      whiteSpace: "normal",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
